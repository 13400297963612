<template>
  <div class="backup-list md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="backupListTable"
                             :columns="columnsLabel"
                             :options="options"
                             @recovery="selectedBackup = $event, $refs.recoveryBackup.showDialog()"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getBackup()"
                             v-model="data"/>

    <button id="InsertBackup" @click="$refs.insertBackup.showDialog()"/>
    <custom-dialog ref="insertBackup"
                   :title="$t('backup.confirmations.insert.title')"
                   :body="$t('backup.confirmations.insert.body')"
                   @accept="insertBackup"/>

    <custom-dialog ref="recoveryBackup"
                   :title="$t('backup.confirmations.recovery.title')"
                   :body="$t('backup.confirmations.recovery.body')"
                   @accept="recoveryBackup(selectedBackup)"/>
  </div>
</template>

<script>
import axios from 'axios'
import CustomDialog from '@/components/customDialog/customDialog'
import {getBackups, insertBackup, restoreBackup} from '@/http/requests/settings/backups'

export default {
  name: 'backup',
  components: {CustomDialog},
  metaInfo () {
    return {
      title: this.$t('backup.title')
    }
  },
  data () {
    return {
      options: {
        id: 'backupListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        /*{
          field: 'recovery',
          i18n: 'backup.list.table.header.recovery',
          width: '70px',
          minWidth: 70,
          /!*sortable: true,*!/
          actions: true,
          showAction: 'always',
          action: {
            color: 'primary',
            icon: 'REFRESH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'recovery'
        },*/
        {
          field: 'status',
          i18n: 'backup.list.table.header.status',
          width: '50%',
          minWidth: 120,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('backup.status.all'),
              value: 0
            },
            {
              label: this.$t('backup.status.saving'),
              value: 1
            },
            {
              label: this.$t('backup.status.saved'),
              value: 2
            },
            {
              label: this.$t('backup.status.retrieved'),
              value: 3
            }
          ]
        },
        {
          field: 'date',
          i18n: 'backup.list.table.header.date',
          width: '50%',
          minWidth: 120,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'date',
          filterTypes: [
            {
              icon: 'calendar',
              name: 'تقویم',
              i18n: '',
              id: 1
            }
          ]
        },
        {
          field: 'rowNumber',
          i18n: 'backup.list.table.header.rowNumber',
          // sortable: true,
          // locked: true,
          align: 'center',
          width: '70px',
          minWidth: 70,
          footer: {}
        }
      ],
      backupStatus: [
        {
          label: this.$t('backup.status.saving'),
          value: 1
        },
        {
          label: this.$t('backup.status.saved'),
          value: 2
        },
        {
          label: this.$t('backup.status.retrieved'),
          value: 3
        }
      ],
      data: [],
      filters: {},
      sorts: ['order[0]=created_at,desc'],
      page: 1,
      endedList: false,
      selectedBackup: null,
      actions: {
        toolbar: [
          {
            id: 'InsertBackup',
            color: 'success',
            icon: 'PLUS',
            iconPack: 'useral',
            permission: 'backup.create'
          }
        ]
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('auth/setAccessToken')
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
    })

    this.getBackup()
  },
  methods: {
    getBackup () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (this.data.length < this.total_count || !this.total_count) {
          if (this.$refs.backupListTable && this.data.length === 0) this.$refs.backupListTable.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.backupListTable && this.data.length > 0) this.$refs.backupListTable.loadMoreStatus = 'Loading'

          getBackups(this.page, this.filters, this.sorts).then((response) => {
            const backups = response.data

            backups.data.forEach((backup) => {
              this.data.push({
                id: backup.id,
                rowNumber: this.data.length + 1,
                date: backup.created_at,
                status: this.backupStatus[backup.status - 1].label
              })
            })
            this.total_count = backups.pagination.total
            this.page = backups.pagination.current_page + 1

            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.backupListTable) this.$refs.backupListTable.loadMoreStatus = ''
          }).catch(error => {
            if (this.$refs.backupListTable && !axios.isCancel(error)) this.$refs.backupListTable.loadMoreStatus = 'Danger'
          })
        }
      }, 400)
    },
    setFilters (filters) {
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'status':
          if (filters[key].search.value > 0) filters_list.status = filters[key].search.value
          break

        case 'date':
          if (filters[key].search !== '') filters_list.createdAt = `${  filters[key].search}`
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getBackup()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=status,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'date':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getBackup()
    },
    insertBackup () {
      insertBackup().then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('backup.notifications.insert.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('backup.notifications.insert.error'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
        }
      })
    },
    recoveryBackup (backup) {
      if (backup.status.id !== 1) {
        restoreBackup(backup.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('backup.notifications.recovery.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('backup.notifications.recovery.cantSend'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('backup.notifications.recovery.error'),
          color: 'danger',
          time: 2400,
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
